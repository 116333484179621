<template>
  <router-view/>
</template>

<style>
* {
  box-sizing: border-box;
}
html {
  font-family: 'Georama', sans-serif;
}
body {
  margin: 0;
  max-width: 800px;
  margin: 0 auto;
}
</style>
